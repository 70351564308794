import React from "react";


import { Section, Text } from "../../components/Core";


const Works = () => {
  return (
    <>
      {/* <!-- Works Area --> */}
      <Section className="position-relative">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <Text className="text-md-left">
              We respect and are committed towards protecting your privacy.
              Publishing, selling or renting any personal data or information to
              any third party, without your consent, is against our ethics.
              <br />
              <br />
              The privacy practices of this statement apply to our services
              available under the domain and subdomains of the site (tuotr.com).
              By visiting this site you agree to be bound by the terms and
              conditions of this privacy policy. If you do not agree, please do
              not use or access our site.
              <br />
              <br />
              This privacy policy does not apply to sites maintained by other
              companies or organizations to which we link and we are not
              responsible for any personal information you submit to third
              parties via our website. Please ensure that you read the privacy
              policy of such other companies or organizations before submitting
              your details.
              <br />
              <br />
              This privacy policy describes the information, as part of the
              normal operation of our services, we collect from you and what may
              happen to that information. This policy is inter alia formulated
              and displayed, to inform you about our information
              collection/retention policies and practices so that you can make
              an informed decision, in relation to the sharing of your personal
              information with us. By accepting the privacy policy and the user
              agreement or the use of the site in general, you give your consent
              to our use and disclosure of your personal information in
              accordance with this privacy policy. This Privacy Policy is
              incorporated into and subject to the terms of the User Agreement.
              This privacy policy is effective upon acceptance of access by you
              to the site.
              <br />
              <br />
              <h4>Privacy Guarantee</h4>
              We agree that we will not sell or rent your personal information
              to third parties for their marketing purposes without your
              explicit consent. From time to time, we may reveal general
              statistical information about our site and visitors, such as
              number of visitors, number and type of goods and services
              purchased, etc. Only those of our employees who need access to
              your information in order to perform their duties, are allowed
              such access. Any employee who violates our privacy and/or security
              policies is subjected to disciplinary action, including possible
              termination and civil and/or criminal prosecution.
              <br />
              <br />
              <h4>Information We Collect</h4>
              The Personal Information is used for two general purposes: to
              contact you, and to provide you with any subsequent services.
              Unless otherwise stated explicitly, this policy applies to
              personal information as disclosed on any of the media.
              <br />
              <br />
              In furtherance of the confidentiality with which we treat Personal
              Information, we have put in place appropriate physical,
              electronic, and managerial procedures to safeguard and secure the
              information we collect online. We use data collection devices such
              as “cookies” on certain pages of the Site to help and analyze our
              web page flow, measure promotional effectiveness, and promote
              trust and safety. “Cookies” are small files placed on your hard
              drive that assist us in providing our services. We offer certain
              features that are only available through the use of a “cookie”.
              Cookies can also help us provide information that is targeted to
              your interests. Most cookies are “session cookies,” which means
              that they are automatically deleted from your hard drive at the
              end of a session. You are always free to decline our cookies if
              your browser permits. Additionally, you may encounter “cookies” or
              other similar devices on certain pages of the Site that are placed
              by third parties. For example, if you view a web page created by a
              user, there may be a “cookie” placed within that web page. We do
              not control the use of cookies by third parties.
              <br />
              <br />
              You acknowledge that you are disclosing Personal Information
              voluntarily. We identify and use your IP address to help diagnose
              problems with our server, and to administer our Website. Your IP
              address is also used to help identify you and to gather broad
              demographic information.
              <br />
              <br />
              <h4>Cookie Policy</h4>
              tuotr.com operates a strict privacy policy and we are committed to
              being transparent about how we use cookies on our website.
              <br />
              <br />
              <b>Why are cookies important? </b>
              Cookies help you make your online experience more efficient and
              relevant to your interests. For instance, they are used to
              remember your preferences on sites you visit often, to remember
              your user ID and the contents of your shopping baskets, and to
              help you navigate between pages efficiently.
              <br />
              <br />
              <b>What is a Cookie? </b>
              A cookie is a small file, or files on your computer, phone, or
              other device with a browser to save snippets of text for reference
              by the website you are visiting. All cookies have expiration dates
              in them that determine how long they stay in your browser:
              <br />
              <br />
              <b>&gt; Session cookies</b> - these are temporary cookies that expire
              (and are automatically erased) whenever you close your browser.
              <br />
              <br />
              <b>&gt; Persistent cookies </b> - these usually have an expiration date
              and so stay in your browser until they expire, or until you
              manually delete them. For example, we use persistent cookies to
              better understand usage patterns so that we can improve the site
              for users of our site. Cookies are grouped into the following
              categories:
              <br />
              <br />
              <b>&gt; Essential</b> - these are cookies that are required for the
              regular operation of our websites.
              <br />
              <br />
              <b>&gt; Functional</b> - these remember your preferences, and are
              intended to make your experience on our websites better for you.
              We do not use functional cookies.
              <br />
              <br />
              <b>&gt; Analytics</b> – these cookies are used for performance
              measurement to understand things including how many people visit
              our websites, how they navigate our sites, and what content is
              popular. This allows us to improve your experience with us.
              <br />
              <br />
              <b>&gt; Advertising</b> - we use advertising cookies, specifically
              Google Ads and Bing, to enable this website to be found by people
              searching for us.
              <br />
              <br />
              <h4> 3rd Party Cookies </h4>
              We do not host third parties, including those of our trusted
              partners, (other than the cookies needed for site analysis,
              addressed above) on our website and therefore do not have any
              other third-party cookies that need to be addressed in this
              policy.
              <br />
              <br />
              To delete cookies from your browser, please refer Settings on your
              browser for further details. You may also change other setting
              related to Privacy and Security under the same section.
            </Text>
          </div>
        </div>
      </Section>
    </>
  );
};

export default Works;
