import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/privacy/Hero";
import Works from "../sections/privacy/Works";
import CTA from "../sections/privacy/CTA";

const IndexPage = () => {
  return (
    <>
      <PageWrapper lightTheme>
        <Hero />
        <Works />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
